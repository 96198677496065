<template>
  <v-layout
    v-if="getPermission('property:create') || getPermission('property:update')"
  >
    <v-col
      class="custom-content-container pt-0"
      cols="12"
      :md="isDialog ? 12 : 9"
      :sm="isDialog ? 12 : 9"
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-layout class="d-block">
          <v-row>
            <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
              <v-container fluid>
                <v-row>
                  <v-col class="pt-0" md="12" sm="12">
                    <v-row>
                      <v-col v-if="!isDialog" class="pt-0" md="12" sm="12">
                        <v-switch
                          v-model="propertyAddress.is_same"
                          class="mt-0"
                          inset
                          color="cyan"
                          label="Company address is same as Site Location"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" sm="6">
                        <!--  <v-switch
                            v-model="trackGPS"
                            class="mt-0"
                            inset
                            label="Get your current location"
                            v-on:change="getUserCurrentAddress"
                            color="cyan"
                          ></v-switch> -->
                      </v-col>
                      <v-col md="6" sm="6">
                        <v-hover v-slot="{ hover }">
                          <div class="custom-google-autocomplete">
                            <v-text-field
                              id="google-address-map"
                              v-model.trim="searchAddress"
                              append-icon="search"
                              dense
                              filled
                              placeholder="Search Address"
                              solo
                              flat
                              color="cyan"
                              :loading="autocompleteLoading"
                              v-on:keyup="getGoogleAddress"
                              v-on:blur="isFocused = false"
                              v-on:focus="isFocused = true"
                            >
                            </v-text-field>
                            <v-list
                              v-if="
                                (hover || isFocused) && autocompleteList.length
                              "
                              two-line
                              elevation="4"
                              class="custom-google-autocomplete-list"
                            >
                              <v-list-item
                                v-for="(address, index) in autocompleteList"
                                :key="index"
                                link
                                v-on:click="selectGoogleAddress(index)"
                              >
                                <v-list-item-icon class="m-0 mr-3 my-auto">
                                  <v-icon color="cyan"> mdi-map-marker </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content
                                  class="py-1"
                                  v-if="
                                    lodash.isEmpty(
                                      address.structured_formatting
                                    ) === false
                                  "
                                >
                                  <v-list-item-title class="font-size-14">{{
                                    address.structured_formatting.main_text
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle class="font-size-12">{{
                                    address.structured_formatting.secondary_text
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content class="py-1" v-else>
                                  <v-list-item-title class="font-size-14">{{
                                    address.description
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Site Location Name
                    </label>
                    <v-text-field
                      v-model.trim="propertyAddress.property_name"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.property_name,
                          'Site Location Name.',
                          100
                        ),
                        validateRules.required(
                          propertyAddress.property_name,
                          'Site Location Name'
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Site Location Name."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500">Unit No.</label>
                    <v-text-field
                      v-model.trim="propertyAddress.unit_no"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.unit_no,
                          'Unit No.',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Unit No."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Address Line 1</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.street_1"
                      :rules="[
                        validateRules.required(
                          propertyAddress.street_1,
                          'Address Line 1'
                        ),
                        validateRules.maxLength(
                          propertyAddress.street_1,
                          'Address Line 1',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 1"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.street_2"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.street_2,
                          'Address Line 2',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 2"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Postal Code</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.zip_code"
                      :rules="[
                        validateRules.required(
                          propertyAddress.zip_code,
                          'Postal Code'
                        ),
                        validateRules.maxLength(
                          propertyAddress.zip_code,
                          'Postal Code',
                          10
                        ),
                      ]"
                      dense
                      filled
                      @keypress="restrictNonNumericInput($event)"
                      placeholder="Postal Code"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Country</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.country"
                      :rules="[
                        validateRules.required(
                          propertyAddress.country,
                          'Country'
                        ),
                        validateRules.maxLength(
                          propertyAddress.country,
                          'Country',
                          10
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Country"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <label>Latitude</label>
                    <v-text-field
                      v-model.trim="propertyAddress.latitude"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.latitude,
                          'Latitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Latitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <label>Longitude</label>
                    <v-text-field
                      v-model.trim="propertyAddress.longitude"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.longitude,
                          'Longitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Longitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <!--   <v-col md="12" sm="12">
                   
                          <label class="font-size-16 font-weight-500"
                            >Remarks</label
                          >
                          <v-textarea
                            v-model.trim="propertyAddress.remarks"
                            :rules="[
                              validateRules.maxLength(
                                propertyAddress.remarks,
                                'Remarks',
                                512
                              ),
                            ]"
                            auto-grow
                            dense
                            filled
                            label="Remarks"
                            solo
                            flat
                            row-height="30"
                            color="cyan"
                            v-on:keypress="(e) => manageLimitdescr(e)"
                            v-on:paste="(e) => onPaste(e)"
                          ></v-textarea>
                          <div md="6" class="text-right"
                          >{{
                            propertyAddress.remarks
                              ? propertyAddress.remarks.length
                              : 0
                          }}/200</div
                        >
                    </v-col> -->
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-layout>
      </perfect-scrollbar>
    </v-col>
    <template v-if="!isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import SupplierMixin from "@/core/plugins/supplier-mixin";
import { QUERY, GET } from "@/core/services/store/request.module";

export default {
  name: "property-address",
  title: "Supplier",
  mixins: [CommonMixin, ValidationMixin, SupplierMixin],
  props: {
    supplier: {
      type: Object,
      default: function () {
        return {};
      },
    },

    defaultPosition: {
      type: Object,
      default: function () {
        return {};
      },
    },
    propertyData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    addressId: {
      type: Number,
      default: 0,
    },
    billing: {
      type: Object,
      default: function () {
        return {};
      },
    },
    updatedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    property: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      propertyAddress: {
        id: null,
        type: 1,
        property_name: null,
        is_same: 0,
        unit_no: null,
        street_1: null,
        street_2: null,
        country: "Singapore",
        zip_code: null,
        latitude: null,
        longitude: null,
        remarks: null,
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    propertyAddress: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("savePropertyAddress", param);
      },
    },
    addressId: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.isUpdate(param);
        }
      },
    },
    /* propertyAddress: {
        deep: true,
        immediate: true,
        handler(param) {
            _this.propertyAddress.longitude = null;
            _this.propertyAddress.street_1 = null;
            _this.propertyAddress.street_2 = null;
            _this.propertyAddress.unit_no = null;
            _this.propertyAddress.country = "Singapore";
            _this.propertyAddress.zip_code = null;
          this.$emit("savePropertyAddress", param);
        },
      }, */
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.propertyAddress.remarks;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.propertyAddress.remarks = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.propertyAddress.remarks &&
        this.propertyAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    },
    isUpdate(id) {
      if (id) {
        const _this = this;
        _this.$store
          .dispatch(GET, { url: `pic-single-address/${id}` })
          .then(({ data }) => {
            _this.propertyAddress.id = data.id;
            _this.propertyAddress.property_name = data.property_name;
            _this.propertyAddress.unit_no = data.unit_no;
            _this.propertyAddress.property_name = data.property_name;
            _this.propertyAddress.street_1 = data.street_1;
            _this.propertyAddress.zip_code = data.zip_code;
            _this.propertyAddress.remarks = data.remarks;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            /* _this.dataLoading = false; */
          });
      }
    },

    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.propertyAddress.latitude = result.latitude;
              _this.propertyAddress.longitude = result.longitude;
              _this.propertyAddress.street_1 = result.street_1;
              _this.propertyAddress.street_2 = result.street_2;
              _this.propertyAddress.unit_no = result.unit_no;
              _this.propertyAddress.zip_code = result.zip_code;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },
  },
  mounted() {
    if (this.property > 0) {
      this.propertyAddress = this.getPropertyById(this.supplier, this.property);
      this.propertyAddress.country = this.propertyAddress.country_name;
    }
  },
};
</script>
