<!-- <template>
  <div class="job-schedule-team-header d-flex">
    <h3 class="m-0 text-uppercase font-weight-700 color-custom-blue">
      <v-layout>
        <v-flex>
          <div>
            <h3
              class="text-uppercase font-weight-700 color-custom-blue margin-auto mr-5"
            >
              <v-select
                style="max-width: 150px"
                :items="teamList"
                v-model="available_team"
                label="team"
                placeholder="getSelectorPlaceholder"
                item-color="cyan"
                item-text="team_name"
                hide-details
                class="pt-0 mt-0 listing-status main-listing-status-filter team-select blue--text"
                item-value="id"
                v-on:change="getAvailableTeam"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content style="min-width: 150px">
                    <v-list-item-title
                      v-text="item.team_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </h3>
            <div class="mt-1" style="max-width: 120px"></div>
          </div>
          <v-tooltip v-if="false" top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-model="all"
                  :value="true"
                  hide-details
                  :disabled="scheduleLater"
                  color="cyan"
                  class="mt-0 pt-0"
                  v-on:change="getAvailableTeam"
                >
                </v-checkbox>
              </div>
            </template>
            <span>Get All Available Engineers</span>
          </v-tooltip>
        </v-flex>
        <v-flex class="position-ralative my-auto">
          <v-menu
            v-if="availableTeam.length"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                right
                small
                :disabled="scheduleLater"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team Member
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in availableTeam"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assignedTeam"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span>
                          </v-tooltip>
                          <v-list-item-subtitle
                            v-if="lodash.isEmpty(team.designation) == false"
                            >{{ team.designation.value }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
            </v-list>
          </v-menu>
          <v-btn
            v-if="availableTeam.length == 0"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            right
            small
            :loading="teamLoading"
            :disabled="scheduleLater || teamLoading"
            v-on:click="getAvailable"
          >
            <v-icon class="mr-2">mdi-account-group</v-icon>Get Available
          </v-btn>
        </v-flex>
      </v-layout>
    </h3>
  </div>
</template> -->
<template>
  <div class="job-schedule-team-header d-flex">
    <h3 class="m-0 text-uppercase font-weight-700 color-custom-blue">
      <v-layout>
        <v-flex>
          Drivers
          <v-tooltip v-if="false" top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-model="all"
                  :value="true"
                  hide-details
                  :disabled="scheduleLater"
                  color="cyan"
                  class="mt-0 pt-0"
                  v-on:change="getAvailableTeam"
                >
                </v-checkbox>
              </div>
            </template>
            <span>Get All Available Engineers</span>
          </v-tooltip>
        </v-flex>
        <v-flex class="position-ralative">
          <v-menu
            v-if="availableTeam.length"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                right
                small
                :disabled="scheduleLater"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Driver
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in availableTeam"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assignedTeam"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-size-16 font-weight-500"
                            v-html="team.display_name"
                          >
                          </v-list-item-title>
                          <template v-if="team && team.role == 2">
                            <v-chip
                              label
                              outlined
                              text-color=""
                              dense
                              class="mr-1"
                              small
                              color="blue"
                              style="max-width: 72px !important"
                              >Technician</v-chip
                            >
                          </template>
                          <template v-if="team && team.is_driver == 1">
                            <v-chip
                              label
                              outlined
                              text-color=""
                              small
                              style="max-width: 48px !important"
                              color="red"
                            >
                              Driver</v-chip
                            >
                          </template>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
            </v-list>
          </v-menu>
          <v-btn
            v-else
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            right
            small
            :loading="teamLoading"
            :disabled="scheduleLater || teamLoading"
            v-on:click="getAvailableTeam"
          >
            <v-icon class="mr-2">mdi-account-group</v-icon>Get Available
          </v-btn>
        </v-flex>
      </v-layout>
    </h3>
  </div>
</template>

<style></style>

<script>
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";

import {
  ClearEventBus,
  ErrorEventBus,
  InitializeError,
} from "@/core/lib/message.lib";
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import { currentUser } from "@/core/services/jwt.service";
import { forEach } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-schedule-team-header",
  data() {
    return {
      teamList: [],
      available_team: null,
      scheduleLater: true,
      startDate: null,
      startTime: null,
      updatedTeam: [],
      endDate: null,
      endTime: null,
      teamLoading: false,
      force: false,
      all: true,
      skills: [],
      assignedTeam: new Array(),
      availableTeam: new Array(),
      availableTeam1: new Array(),
      timeout: null,
      timeoutLimit: 1000,
    };
  },
  watch: {
    assignedTeam: {
      deep: true,
      immediate: true,
      handler() {
        if (this.availableTeam && this.availableTeam.length > 0) {
          JobScheduleTeamEventBus.$emit("update:schedule-team", {
            availableTeam: this.availableTeam,
            assignedTeam: this.assignedTeam,
            teamId: this.available_team,
          });
        }
      },
    },
  },
  methods: {
    getCurrentUser() {
      const user = currentUser();
      if (user && user.role_name == "Staff") {
        this.assignedTeam = user.id;
      }
      return user;
    },
    getAvailable() {
      ErrorEventBus.$emit(
        "update:error",
        InitializeError("Please select Driver first.")
      );
    },
    /* getAvailableTeam() {
     
      const _this = this;
      _this.availableTeam = [];
      _this.assignedTeam = [];

      if (_this.scheduleLater) {
       
        return false;
      }

      ClearEventBus.$emit("clear:success");

      if (
        moment(_this.startDate + " " + _this.startTime).isAfter(
          _this.endDate + " " + _this.endTime
        )
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Start date & time must before End date & time.")
        );
        return false;
      }

      if (!_this.all && _this.lodash.isEmpty(_this.skills)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select job type.")
        );
        return false;
      }

      if (_this.lodash.isEmpty(_this.startDate)) {
        return false;
      }
      if (!_this.available_team || _this.available_team == 0) {
        return false;
      }
      _this.teamLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: _this.startDate,
            start_time: _this.startTime,
            end_date: _this.endDate,
            end_time: _this.endTime,
            force: +_this.force,
            all: +_this.all,
            skills: _this.skills,
            team_id: _this.available_team,
          },
        })
        .then(({ data }) => {
          
          _this.availableTeam = data.engineers;
          _this.availableTeam1 = data;
          
        
          JobScheduleTeamEventBus.$emit("update:schedule-text", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    }, */
    getAvailableTeam() {
      const _this = this;
      _this.availableTeam = [];
      //_this.assignedTeam = [];

      if (_this.scheduleLater) {
        return false;
      }

      ClearEventBus.$emit("clear:success");

      /*  if (
        moment(_this.startDate + " " + _this.startTime).isAfter(
          _this.endDate + " " + _this.endTime
        )
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Start date & time must before End date & time.")
        );
        return false;
      } */

      if (!_this.all && _this.lodash.isEmpty(_this.skills)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select job type.")
        );
        return false;
      }

      if (_this.lodash.isEmpty(_this.startDate)) {
        return false;
      }

      _this.teamLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-Drivers",
          data: {
            start_date: _this.startDate,
            start_time: _this.startTime,
            end_date: _this.endDate,
            end_time: _this.endTime,
            force: +_this.force,
            all: 1,
            /*  skills: _this.skills, */
          },
        })
        .then(({ data }) => {
          _this.availableTeam = data.engineers;

          JobScheduleTeamEventBus.$emit("update:schedule-text", data);
          JobScheduleTeamEventBus.$emit("update:schedule-team", {
            availableTeam: this.availableTeam,
            assignedTeam: this.assignedTeam,
            teamId: this.available_team,
          });

          /*  if (this.is_staff && data.engineers && data.engineers.length > 0) {
              forEach(data.engineers, (row3) => {
                this.assignedTeam.push(row3.id);
              }); 
          } */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },
    getAvailableTeamList() {
      const _this = this;
      _this.teamLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-team",
        })
        .then(({ data }) => {
          _this.teamList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "staff";
    },
    /* 
    getCurrentUserData() {
      const user = currentUser();
      if (user && user.role_name == "Staff") {
        return user.engineer;
      }
      else
      {
        return false;
      }
    }, */
  },
  beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("get:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("remove:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("update:skills");*/
  },
  mounted() {
    /*  this.getAvailableTeamList(); */

    JobScheduleTeamEventBus.$emit("get:skills");
  },
  beforeMount() {
    /* this.job_id = this.$route.query.job-id */
    /* console.log(this.$route.query.module_type) */
    JobScheduleTeamEventBus.$emit("update:schedule-team", {
      availableTeam: [],
      assignedTeam: [],
      teamId: [],
    });
  },
  created() {
    const _this = this;
    JobScheduleTeamEventBus.$on("get:schedule-team", (param) => {
      try {
        _this.startDate = param.start_date
          ? moment(param.start_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        _this.startTime = param.start_time
          ? moment(param.start_time, ["h:mm A"]).format("HH:mm")
          : moment().startOf("day").format("HH:mm");
        _this.endDate = param.end_date
          ? moment(param.end_date).format("YYYY-MM-DD")
          : moment(_this.startDate).format("YYYY-MM-DD");
        _this.endTime = param.end_time
          ? moment(param.end_time, ["h:mm A"]).format("HH:mm")
          : moment().endOf("day").format("HH:mm");
        _this.availableTeam = new Array();
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.getAvailableTeam();
        }, _this.timeoutLimit);
        if (param.schedule_later === 1) {
          _this.assignedTeam = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    });

    JobScheduleTeamEventBus.$on("remove:schedule-team", ({ index }) => {
      _this.assignedTeam.splice(index, 1);
    });

    JobScheduleTeamEventBus.$on("update-value:one-off-schedule", (param) => {
      /*   console.log(param.engineers,'paramparam') */
      this.updatedTeam = param.engineers;
      if (this.updatedTeam) {
        forEach(this.updatedTeam, (row3) => {
          this.assignedTeam.push(row3.id);
        });
      }
      /* if (this.is_staff && data.engineers && data.engineers.length > 0) {
            this.assignedTeam.push(data.engineers[0].id);
          } */
    });
    JobScheduleTeamEventBus.$on("update:schedule-later", (param) => {
      if (param) {
        _this.assignedTeam = new Array();
      }
      _this.scheduleLater = param;
      if (_this.scheduleLater) {
        _this.availableTeam = [];
        _this.assignedTeam = [];
      }
    });

    JobScheduleTeamEventBus.$on("update:skills", (param) => {
      if (_this.lodash.isEmpty(param) === false) {
        _this.all = false;
      } else {
        _this.all = true;
      }
      _this.skills = param;
      _this.getAvailableTeam();
    });
  },
};
</script>
