<template>
  <div class="one-off-schedule">
    <v-container fluid class="pt-0 px-0">
      <v-layout class="schedule-tab-item" :class="{ 'd-block': showCalendar }">
        <v-flex :md12="showCalendar" :md8="!showCalendar">
          <div class="schedule-tab-item-header custom-grey-border p-3">
            <OneOffScheduleHeader
              :showCalendar="showCalendar"
              :internal="internal"
            ></OneOffScheduleHeader>
          </div>
          <div class="schedule-tab-item-body custom-grey-border p-3">
            <OneOffScheduleBody
              :showCalendar="showCalendar"
              :internal="internal"
              :dailyRecurrence="dailyRecurrence"
              :monthlyRecurrence="monthlyRecurrence"
              :weeklyRecurrence="weeklyRecurrence"
            ></OneOffScheduleBody>
          </div>
        </v-flex>
        <v-flex
          :md12="showCalendar"
          :md4="!showCalendar"
          :class="{ 'ml-3': !showCalendar, 'mt-3': showCalendar }"
        >
          <JobScheduleTeam></JobScheduleTeam>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import OneOffScheduleHeader from "@/view/pages/delivery-job/partials/One-Off-Schedule-Header.vue";
import OneOffScheduleBody from "@/view/pages/delivery-job/partials/One-Off-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/delivery-job/partials/Job-Schedule-Team.vue";

export default {
  name: "one-off-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    dailyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    monthlyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    weeklyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  components: {
    OneOffScheduleHeader,
    OneOffScheduleBody,
    JobScheduleTeam,
  },
};
</script>
