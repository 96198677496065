<template>
  <div class="job-schedule-team-body">
    <v-layout class="d-block">
      <template v-if="team.assigned_team.length > 0">
        <v-col md="12">
          <v-list-item
            v-for="(engineer, index) in team.assigned_team"
            :key="index"
            class="ma-2"
          >
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getTeamUserProfile(engineer)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-14 font-weight-500">
                {{ getTeamUserName(engineer) }}</v-list-item-title
              >
              <v-list-item-subtitle class="font-size-12 font-weight-500">
                {{ getTeamDesignation(engineer) }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon
              v-on:click="removeAssignedTeam({ engineer, index })"
              link
            >
              <v-btn icon color="red lighten-1">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-col>
        <!--  <v-col md="12">
          <v-checkbox
            label="Email team about assignment"
            :value="1"
            hide-details
            class="mr-4 mt-0"
            color="cyan"
            v-model.trim="team.email_team"
          ></v-checkbox>
        </v-col> -->
      </template>
      <v-col md="12" v-else>
        <p class="font-size-16">No drivers are currently assigned</p>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";

export default {
  name: "job-schedule-team-body",
  data() {
    return {
      availableTeam: [],
      team: {
        assigned_team: new Array(),
        teamId: 0,
        email_team: 1,
      },
    };
  },
  watch: {
    team: {
      deep: true,
      immediate: true,
      handler(param) {
        JobScheduleTeamEventBus.$emit("update:assigned-team", param);
      },
    },
  },
  methods: {
    getTeamUserName(id) {
      const _this = this;
      let index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });
      if (index >= 0) {
        return _this.availableTeam[index].display_name;
      }
      return "N/A";
    },
    getTeamDesignation(id) {
      const _this = this;
      let index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });
      if (index >= 0) {
        if (
          _this.lodash.isEmpty(_this.availableTeam[index].designation) === false
        ) {
          return _this.availableTeam[index].designation.value;
        }
        return "";
      }
      return "N/A";
    },
    getTeamUserProfile(id) {
      const _this = this;
      let index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });
      if (index >= 0) {
        return this.$assetAPIURL(_this.availableTeam[index].profile_logo);
      }
      return null;
    },
    removeAssignedTeam({ engineer, index }) {
      JobScheduleTeamEventBus.$emit("remove:schedule-team", {
        engineer,
        index,
      });
    },
  },
  beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("update:schedule-team");*/
  },
  created() {
    const _this = this;
    JobScheduleTeamEventBus.$on(
      "update:schedule-team",
      ({ availableTeam, assignedTeam, teamId }) => {
        _this.availableTeam = availableTeam;
        _this.team.assigned_team = assignedTeam;
        /* console.log(_this.team) */
        _this.team.teamId = teamId;
      }
    );
  },
};
</script>
