<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-layout class="d-block">
          <v-row>
            <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
              <v-container fluid>
                <v-row>
                  <v-col class="pt-0" md="12" sm="12">
                    <v-row>
                      <v-col v-if="!isDialog" class="pt-0" md="12" sm="12">
                        <v-switch
                          v-model="billingAddress.is_same"
                          class="mt-0"
                          inset
                          :true-value="1"
                          :false-value="0"
                          color="cyan"
                          label="Billing address is same as Address"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" sm="6">
                        <v-switch
                          v-if="false"
                          v-model="trackGPS"
                          class="mt-0"
                          inset
                          label="Get your current location"
                          v-on:change="getUserCurrentAddress"
                          color="cyan"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" sm="6">
                        <v-hover v-slot="{ hover }">
                          <div class="custom-google-autocomplete">
                            <v-text-field
                              id="address-map"
                              v-model.trim="searchAddress"
                              append-icon="search"
                              dense
                              filled
                              label="Search Address"
                              solo
                              flat
                              color="cyan"
                              :loading="autocompleteLoading"
                              v-on:keyup="getGoogleAddress"
                              v-on:blur="isFocused = false"
                              v-on:focus="isFocused = true"
                            >
                            </v-text-field>
                            <v-list
                              v-if="
                                (hover || isFocused) && autocompleteList.length
                              "
                              two-line
                              elevation="4"
                              class="custom-google-autocomplete-list"
                            >
                              <v-list-item
                                v-for="(address, index) in autocompleteList"
                                :key="index"
                                link
                                v-on:click="selectGoogleAddress(index)"
                              >
                                <v-list-item-icon class="m-0 mr-3 my-auto">
                                  <v-icon color="cyan"> mdi-map-marker </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content
                                  class="py-1"
                                  v-if="
                                    lodash.isEmpty(
                                      address.structured_formatting
                                    ) === false
                                  "
                                >
                                  <v-list-item-title class="font-size-14">{{
                                    address.structured_formatting.main_text
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle class="font-size-12">{{
                                    address.structured_formatting.secondary_text
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content class="py-1" v-else>
                                  <v-list-item-title class="font-size-14">{{
                                    address.description
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12" class="mb-4" sm="12" v-if="!isDialog">
                    <v-row>
                      <v-col md="4">
                        <label class="font-size-16 font-weight-500"
                          >Contact Person
                        </label>
                        <v-select
                          id="contact-persons"
                          v-model.trim="billingAddress.contact_person"
                          :items="cc_persons"
                          :rules="[
                            validateRules.required(
                              billingAddress.contact_person,
                              'Contact Person'
                            ),
                          ]"
                          dense
                          filled
                          item-text="display_name"
                          item-value="uuid"
                          item-color="cyan"
                          label="Contact Persons"
                          solo
                          flat
                          color="cyan"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Site Location Name
                    </label>
                    <v-text-field
                      v-model.trim="billingAddress.property_name"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.property_name,
                          'Site Location Name.',
                          100
                        ),
                        validateRules.required(
                          billingAddress.property_name,
                          'Site Location Name'
                        ),
                      ]"
                      dense
                      filled
                      label="Site Location Name."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500">Unit No.</label>
                    <v-text-field
                      v-model.trim="billingAddress.unit_no"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.unit_no,
                          'Unit No.',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Unit No."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Address Line 1
                    </label>
                    <v-text-field
                      v-model.trim="billingAddress.street_1"
                      :rules="[
                        validateRules.required(
                          billingAddress.street_1,
                          'Address Line 1'
                        ),
                        validateRules.maxLength(
                          billingAddress.street_1,
                          'Address Line 1',
                          255
                        ),
                      ]"
                      dense
                      filled
                      label="Address Line 1"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="billingAddress.street_2"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.street_2,
                          'Address Line 2',
                          255
                        ),
                      ]"
                      dense
                      filled
                      label="Address Line 2"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Postal Code
                    </label>
                    <v-text-field
                      v-model.trim="billingAddress.zip_code"
                      :rules="[
                        validateRules.required(
                          billingAddress.zip_code,
                          'Postal Code'
                        ),
                        validateRules.maxLength(
                          billingAddress.zip_code,
                          'Postal Code',
                          10
                        ),
                        validateRules.minLength(
                          billingAddress.zip_code,
                          'Postal Code',
                          2
                        ),
                      ]"
                      dense
                      filled
                      v-mask="'######'"
                      label="Postal Code"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Country</label
                    >
                    <v-text-field
                      v-model.trim="billingAddress.country"
                      :rules="[
                        validateRules.required(
                          billingAddress.country,
                          'Country'
                        ),
                        validateRules.maxLength(
                          billingAddress.country,
                          'Country',
                          10
                        ),
                      ]"
                      dense
                      filled
                      label="Country"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <v-text-field
                      v-model.trim="billingAddress.latitude"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.latitude,
                          'Latitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Latitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <v-text-field
                      v-model.trim="billingAddress.longitude"
                      :rules="[
                        validateRules.maxLength(
                          billingAddress.longitude,
                          'Longitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Longitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" sm="12" v-if="!isDialog">
                    <v-row class="py-0">
                      <v-col class="py-0" md="6">
                        <label class="font-size-16 font-weight-500"
                          >Remarks</label
                        >
                        <v-textarea
                          v-model.trim="billingAddress.remarks"
                          :rules="[
                            validateRules.maxLength(
                              billingAddress.remarks,
                              'Remarks',
                              200
                            ),
                          ]"
                          auto-grow
                          dense
                          filled
                          label="Remarks"
                          solo
                          flat
                          row-height="30"
                          v-on:keypress="(e) => manageLimitdescr(e)"
                          v-on:paste="(e) => onPaste(e)"
                          color="cyan"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-col md="6" class="text-right"
                      >{{
                        billingAddress.remarks
                          ? billingAddress.remarks.length
                          : 0
                      }}/200</v-col
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-layout>
      </perfect-scrollbar>
    </v-col>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { QUERY, GET } from "@/core/services/store/request.module";

export default {
  name: "billing-address",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    property: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    addressId: {
      type: Number,
      default: 0,
    },
    defaultPosition: {
      type: Object,
      default: function () {
        return {};
      },
    },
    billingData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      billingAddress: {
        id: null,
        uuid: null,
        type: 2,
        is_same: 1,
        contact_person: null,
        unit_no: null,
        property_name: null,
        street_1: null,
        street_2: null,
        zip_code: null,
        country: "Singapore",
        latitude: null,
        longitude: null,
        remarks: null,
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    cc_persons: {
      deep: true,
      handler() {
        this.billingAddress.contact_person = null;
      },
    },
    addressId: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.isUpdate(param);
        }
      },
    },
    billingAddress: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.$emit("updateAdress", param);
        }
      },
    },
  },
  methods: {
    /* manageLimit(e) {
        if (
          this.billingAddress.remarks &&
          this.billingAddress.remarks.length > 199
        ) {
          e.preventDefault();
        }
      }, */

    isUpdate(id) {
      if (id) {
        const _this = this;
        _this.$store
          .dispatch(GET, { url: `property/${id}` })
          .then(({ data }) => {
            _this.billingAddress.id = data.id;
            _this.billingAddress.unit_no = data.unit_no;
            _this.billingAddress.property_name = data.property_name;
            _this.billingAddress.street_1 = data.street_1;
            _this.billingAddress.zip_code = data.zip_code;
            _this.billingAddress.remarks = data.remarks;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            /* _this.dataLoading = false; */
          });
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.billingAddress.remarks;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.billingAddress.remarks = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.billingAddress.remarks &&
        this.billingAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    },

    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.billingAddress.latitude = null;
      _this.billingAddress.longitude = null;
      _this.billingAddress.street_1 = null;
      // _this.billingAddress.property_name = null;
      _this.billingAddress.street_2 = null;
      //_this.billingAddress.unit_no = null;
      _this.billingAddress.country = "Singapore";
      _this.billingAddress.zip_code = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.billingAddress.latitude = result.latitude;
              _this.billingAddress.longitude = result.longitude;
              _this.billingAddress.street_1 = result.street_1;
              _this.billingAddress.street_2 = result.street_2;
              // _this.billingAddress.property_name = result.property_name;
              // _this.billingAddress.unit_no = result.unit_no;
              _this.billingAddress.zip_code = result.zip_code;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },
  },
  computed: {
    ...mapGetters(["cc_persons"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.billing > 0) {
        let result = this.getBillingAddress(this.customer);
        if (this.lodash.isEmpty(result) === false) {
          this.billingAddress = result;
          this.billingAddress.country = result.country_name;
        }
      }
    });
  },
};
</script>
